<template>
  <v-app >
    <Background />
    
    <v-app-bar
      app
      color="black"
      dark
      dense
      fixed
    >
    <v-spacer></v-spacer>
      <div class="d-flex align-right">
        <v-icon
          large
          color="grey lighten-5"
          :style="'transform: scaleX(' + (flipped ? -1 : 1) + ')'"
        >
          mdi-coffin
        </v-icon>
      </div>
      <router-link
        to="/"
        style="text-decoration: none;"
      >
        <span class="yellow--text text--darken-1" style="font-family: Italiana, sans-serif !important; font-size: 1.5em; font-weight: bold;">Afterlife Awards 2022</span>
      </router-link>
      <div class="d-flex align-right">
        <v-icon
          large
          color="grey lighten-5"
          :style="'transform: scaleX(' + (flipped ? 1 : -1) + ')'"
        >
          mdi-coffin
        </v-icon>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main >
      <router-view/>
    </v-main>
    <v-bottom-navigation app fixed grow v-if="!isHome && !isScreen" shift color="yellow darken-1" background-color="black">
      <v-btn color="black" style="height:inherit" to="/">
        <span>Home</span>

        <v-icon>mdi-coffin</v-icon>
      </v-btn>

      <v-btn color="black" style="height:inherit" to="/register">
        <span>Register</span>

        <v-icon>mdi-emoticon-devil</v-icon>
      </v-btn>

      <v-btn color="black" style="height:inherit" to="/vote">
        <span>Vote</span>

        <v-icon>mdi-knife-military</v-icon>
      </v-btn>

      <v-btn color="black" style="height:inherit" to="/results">
        <span>Results</span>

        <v-icon>mdi-candelabra-fire</v-icon>
      </v-btn>
    </v-bottom-navigation>

      
      <v-snackbar
        v-model="$store.state.snackbar"
        multi-line
        :timeout="5000"
        :class="isHome ? '' : 'mb-12'"
        app
        color="rgba(0, 0, 0, 0.90)"
        tile
        v-if="!isScreen"
      >
        <v-icon color="yellow darken-1" small>{{snackbarContent.icon}}</v-icon> {{snackbarContent.text}}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="yellow darken-1"
            text
            v-bind="attrs"
            @click="$store.state.snackbar = false"
            icon
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-overlay
        absolute
        dark
        opacity=".95"
        color="black"
        :value="noOffice"
      >
        <v-container class="text-center pa-6">
          <v-card-text>
            <p class="text-h4 mb-12" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">
              Welcome to the Afterlife Awards 2022
            </p>
            <p class="text-h6">
              Select your party location...if you dare!
            </p>
            <p class="mb-12 text-caption font-italic">
              I mean, nothing bad will happen to you it's just if you want to participate in the costume contest...or want to vote in the costume contest...or want to see the live results of the costume contest...really just do it or the nerds who built this will be sad
            </p>
            <v-select
              :items="offices"
              label="Party Location"
              v-model="selectedOffice"
              outlined
              dense
            ></v-select>
            <v-btn
              color="success"
              class="mr-4"
              @click="initOffice()"
              block
              :disabled="!this.selectedOffice"
            >
              Let's Party!
            </v-btn>
          </v-card-text>
        </v-container>
      </v-overlay>
    
  </v-app>
</template>

<script>
import Background from '@/components/Background.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Background
  },
  computed: {
      ...mapGetters(['winHeight','voterid','office','offices','categories','catMap','snackbarContent']),
      isHome() {
        return this.$route.name === 'Home'
      },
      isScreen() {
        return this.$route.name === 'Results Screen'
      }
    },
  data: () => ({
    flipped: false,
    noOffice: false,
    selectedOffice: "",
    value: "home"
  }),
  watch:{
    $route (to, from){
        console.log("LEAVING: " + from);
        this.$vuetify.goTo(0);
    }
},
  beforeCreate() { 
    this.$store.commit('initialiseStore');
    this.$store.commit('initialiseOfficeStore');
  },
  async beforeMount() {
    let vm = this;
    setInterval(() => vm.flipped = !this.flipped,10000);

    if(this.office){
      await this.initVoter();
      this.initParticipants();
    }

    window.addEventListener("resize", function () {
      vm.onResize();
    });
  },
  mounted(){
    this.noOffice = !this.office;
    this.onResize();
  },
  methods:{
    onResize () {
      this.$store.commit('updateWinSize');
    },
    initOffice(){
      this.$store.commit('initialiseOfficeStore',this.selectedOffice);
      this.initVoter();
      this.initParticipants();
      this.noOffice = false;
    },
    async initVoter(){
      const voterRes = await this.getVoter();
      console.log(voterRes);
      if(!voterRes){
        const body = {
          "voterid": this.voterid,
          "office": this.office,
          "votedfor":[]
        }
        let catShorts = Object.keys(this.catMap);
        for (let c=0; c<catShorts.length; c++){
          body[catShorts[c]] = false;
        }

        const voterCreateRes = await this.createVoter(JSON.stringify(body));
        console.log(voterCreateRes);
        this.$store.commit('updateVoterInfo', body);
      }else{
        this.$store.commit('updateVoterInfo', voterRes);
      }
    },
    async getVoter(){
      try {
        const fetchResponse = await fetch("https://7ibv9h4nu3.execute-api.us-east-1.amazonaws.com/v1/voter/d6aa22/" + this.voterid);
        const data = await fetchResponse.json();
        return data;
      } catch (e) {
        return e;
      }
    },
    async createVoter(body){
      const settings = {
                method: "POST",
                body: body,
                headers: {
                  "Content-type": "application/json"
                }
              };
        try {
          const fetchResponse = await fetch("https://7ibv9h4nu3.execute-api.us-east-1.amazonaws.com/v1/createVoter/d6aa22",settings);
          const data = await fetchResponse.json();
          return data;
        } catch (e) {
          return e;
        }
    },
    async initParticipants(){
      const participantRes = await this.getParticipants();
      console.log(participantRes);
      this.$store.commit('updateParticipants', participantRes);
    },
    async getParticipants(){
      try {
        const fetchResponse = await fetch("https://7ibv9h4nu3.execute-api.us-east-1.amazonaws.com/v1/participants/d6aa22?office=" + this.office);
        const data = await fetchResponse.json();
        return data;
      } catch (e) {
        return e;
      }
    },
  }
};
</script>

