<template>
  <v-container fluid fill-height>
    <v-row class="mt-0">
      <v-col lg="6" offset-lg="3" cols="10" offset="1" class="my-lg-6">
        <p class="text-body-1 yellow--text  mb-1">
          <span class="font-weight-bold text-uppercase">To enter the contest:</span><span> Click REGISTER, add your info & upload a selfie</span>
        </p>
        <p class="text-body-1 yellow--text  mb-n5">
          <span class="font-weight-bold text-uppercase">Don't want to enter, but want to vote:</span><span> Just click VOTE!</span>
        </p>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col lg="4" offset-lg="4" cols="8" offset="2">
        <v-btn
          to="register"
          x-large
          color="black"
          dark
          tile
          block
          height="100" class="my-4"
          style="font-family: Italiana, sans-serif !important; font-size: 1.5em; font-weight: bold; color: #FDD835;"
        >
          <v-icon
            left
            dark
            large
            color="white"
            :style="'transform:rotate(' + (flipped ? 360 : 0) + 'deg)'"
          >
            mdi-emoticon-devil
          </v-icon>
          &nbsp;
          Register!
        </v-btn>
        <v-btn
          to="vote"
          x-large
          color="black"
          dark
          tile
          block
          height="100" class="my-4"
          style="font-family: Italiana, sans-serif !important; font-size: 1.5em; font-weight: bold; color: #FDD835;"
        >
          <v-icon
            left
            dark
            large
            color="white"
            :style="'transform: scaleX(' + (flipped ? -1 : 1) + ')'"
          >
            mdi-knife-military
          </v-icon>
          &nbsp;
          Vote!
        </v-btn>
        <v-btn
          to="results"
          x-large
          color="black"
          dark
          tile
          block
          height="100" class="my-4"
          style="font-family: Italiana, sans-serif !important; font-size: 1.5em; font-weight: bold; color: #FDD835;"
        >
          <v-icon
            left
            dark
            large
            color="white"
            :style="'transform:rotate(' + (flipped ? 360 : 0) + 'deg)'"
          >
            mdi-candelabra-fire
          </v-icon>
          &nbsp;
          Results
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HomePage',
    data: () => ({
      flipped: false
    }),
    mounted() {
      let vm = this;
      setInterval(() => vm.flipped = !this.flipped,15000);
    },
  }
</script>
