import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { ulid } from 'ulid'

export default new Vuex.Store({
    state: {
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
        },
        winHeight: window.screen.availHeight,
        //winHeight: document.clientHeight,
        voterid: "",
        office: null,
        offices: ['NYC', 'ATL'],
        voterInfo: {},
        categories: [{
            text: 'Best Costume',
            value: 'BestCostume',
            icon: 'mdi-trophy-award'
        }, {
            text: 'Scariest Costume',
            value: 'ScariestCostume',
            icon: 'mdi-axe'
        }, {
            text: 'Funniest Costume',
            value: 'FunniestCostume',
            icon: 'mdi-drama-masks'
        }, {
            text: 'Most Original',
            value: 'MostOriginal',
            icon: 'mdi-arm-flex'
        }],
        catMap: { 'BestCostume': 'Best Costume', 'ScariestCostume': 'Scariest Costume', 'FunniestCostume': 'Funniest Costume', 'MostOriginal': 'Most Original' },
        trophyMap: { 'BestCostume': 'mdi-trophy-award', 'ScariestCostume': 'mdi-axe', 'FunniestCostume': 'mdi-drama-masks', 'MostOriginal': 'mdi-arm-flex' },
        participants: [],
        snackbar: false,
        snackbarContent: {
            text: "Cowman Bull2 has received a vote for Best Costume!",
            icon: "mdi-candelabra-fire"
        }
    },
    getters: {
        winHeight(state) {
            return state.winHeight;
        },
        voterid(state) {
            return state.voterid;
        },
        office(state) {
            return state.office;
        },
        offices(state) {
            return state.offices;
        },
        voterInfo(state) {
            return state.voterInfo;
        },
        categories(state) {
            return state.categories;
        },
        catMap(state) {
            return state.catMap;
        },
        trophyMap(state) {
            return state.trophyMap;
        },
        participantList(state) {
            let out = []
            for (let p = 0; p < state.participants.length; p++) {
                out.push(state.participants[p].email);
            }
            return out;
        },
        participants(state) {
            return state.participants;
        },
        snackbarContent(state) {
            return state.snackbarContent;
        }
    },
    mutations: {
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget
            console.log("OPENED SOCKET");
            console.log(event);
            state.socket.isConnected = true
        },
        SOCKET_ONCLOSE(state, event) {
            state.socket.isConnected = false
            console.log("CLOSED SOCKET");
            console.log(event);
        },
        SOCKET_ONERROR(state, event) {
            console.error(state, event);
            console.log("SOCKET ERROR");
            console.log(event);
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            console.log("SOCKET MESSAGE");
            console.log(message);
            state.socket.message = message;
            if (message.email && message.office == state.office) {
                if (this.getters.participantList.includes(message.email)) {
                    for (let p = 0; p < state.participants.length; p++) {
                        if (state.participants[p].email == message.email) {
                            state.participants[p] = message;
                            state.snackbar = false;
                            state.snackbar = true;
                            state.snackbarContent.text = `${message.name} as ${message.celebName} has received a vote, go checkout the standings!`;
                            state.snackbarContent.icon = "mdi-candelabra-fire";
                            break;
                        }
                    }
                } else {
                    state.participants.push(message);
                    state.snackbar = false;
                    state.snackbar = true;
                    state.snackbarContent.text = `${message.name} as ${message.celebName} has entered the competition...go vote!`;
                    state.snackbarContent.icon = "mdi-knife-military";
                }
            }
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        },
        initialiseStore(state) {
            if (!localStorage.getItem('voterid')) {
                localStorage.setItem('voterid', ulid());
            }
            state.voterid = localStorage.getItem('voterid');
        },
        initialiseOfficeStore(state, payload) {
            if (!localStorage.getItem('office') && payload) {
                localStorage.setItem('office', payload);
                state.office = payload;
            } else {
                state.office = localStorage.getItem('office');
            }
        },
        updateVoterInfo(state, payload) {
            state.voterInfo = payload;
            const cats = JSON.parse(JSON.stringify(state.categories));
            for (let c = 0; c < cats.length; c++) {
                if (payload[cats[c].value]) {
                    state.categories = state.categories.filter(e => e.value !== cats[c].value);
                }
            }
        },
        updateWinSize(state) {
            state.winHeight = window.innerHeight;
        },
        updateVotedFor(state, payload) {
            state.voterInfo.votedfor.push(payload);
        },
        updateParticipants(state, payload) {
            state.participants = payload;
        },
        pushParticipant(state, payload) {
            state.participants.push(payload);
        },
        updateCategories(state, payload) {
            state.categories = payload;
        },
    },
    actions: {
        patricipantChanged(context) {
            console.log('action received: participant change');
            console.log(state.socket.message);
        }
    },
    modules: {}
})