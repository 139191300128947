import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import VueNativeSock from 'vue-native-websocket'
Vue.use(VueNativeSock, 'wss://1ynjdkx85k.execute-api.us-east-1.amazonaws.com/v1', { store: store, format: 'json', reconnection: true })

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')