<template>
  <div class="home">
    <Hero />
    <HomePage />
  </div>
</template>

<script>
// @ is an alias to /src
import HomePage from '@/components/HomePage.vue'
import Hero from '@/components/Hero.vue'

export default {
  name: 'Home',
  components: {
    HomePage,Hero
  }
}
</script>
