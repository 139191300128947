import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Registration.vue')
    },
    {
        path: '/vote',
        name: 'Vote',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Vote.vue')
    },
    {
        path: '/results',
        name: 'Results',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Results.vue')
    },
    {
        path: '/resultsscreen',
        name: 'Results Screen',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ResultsScreen.vue')
    }
]

import goTo from 'vuetify/lib/services/goto'

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        console.log("SCROLLING TO TOP")
        return goTo(0)
    },
})

export default router