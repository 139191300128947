<template>
  <v-container fluid ma-0 pa-0 fill-height style="position:absolute;overflow:hidden">
    <v-row v-for="i in 3" :key="i">
      <v-col
        v-for="(img, key) in images"
        :key="key"
        class="d-flex child-flex"
        cols="3"
        
      >
        <v-img
          :src="'bgroundimgs'+key.substr(1)"
          :lazy-src="'bgroundimgs'+key.substr(1)"
          aspect-ratio="1"
          class="grey lighten-2"
          style="filter: blur(3px);opacity: .25;"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <!-- <div :key="key" v-for="(img, key) in images" >
      {{img}} - {{key.substr(1)}}
      <v-img :src="'/bgroundimgs/'+key.substr(1)" class="" ></v-img>
    </div> -->
  </v-container>
</template>

<script>
  export default {
    name: 'Background',
    
    data: () => ({
      imageDir: "/public/bgroundimgs",
      images: {}
    }),
    mounted() {
      this.importAll(require.context("../assets/bgroundimgs", true, /\.png$/))
    },
    methods: {
      importAll(r) {
        var imgs = {}
        r.keys().forEach(key => (imgs[key] = r(key)))
        this.images = this.shuffleObject(imgs);
      },
      shuffleObject(obj){
    let newObj = {};
    var keys = Object.keys(obj);
      keys.sort(function(a,b){return Math.random()- 0.5;});
      keys.forEach(function(k) {
          newObj[k] = obj[k];
  });
    return newObj;
  }
    }
  }
</script>
